<template>
  <div class="label-list">
    <el-form :inline="true" class="form-content">
      <el-form-item label="标签名称">
        <el-input
          placeholder="请输入关键字查询"
          v-model="labelName"
          class="input-with-select"
          style="width: 400px"
        >
          <el-button
            slot="append"
            @click="getList"
            icon="el-icon-search"
          ></el-button>
        </el-input>
      </el-form-item>
      <el-form-item label="标签类型">
        <el-select
          placeholder="请选择标签类型"
          v-model="labelType"
          clearable
          style="width: 200px"
        >
          <el-option
            v-for="(item, index) in typeList"
            :key="index"
            :value="item.value"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="创建时间">
        <el-date-picker
          v-model="dateRangeTime"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          clearable
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList">查询</el-button>
        <el-button @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <!-- <el-form :inline="true" class="form-content"> </el-form> -->
    <div class="search-list">
      <el-button type="primary" @click="add">添加</el-button>
      <el-button
        type="danger"
        @click="deleteHandle"
        :disabled="dataListSelections.length ? false : true"
        >删除</el-button
      >
    </div>
    <div class="table">
      <el-table
        :data="dataList"
        style="width: 100%"
        v-loading="dataListLoading"
        @selection-change="selectionChangeHandle"
      >
        <el-table-column type="selection" width="50"></el-table-column>
        <el-table-column prop="labelName" label="标签名称"></el-table-column>
        <el-table-column
          prop="labelTypeName"
          label="标签类型"
        ></el-table-column>
        <!-- <el-table-column prop="teamsName" label="工作室名称" ></el-table-column> -->
        <el-table-column prop="createTime" label="创建日期"></el-table-column>
        <el-table-column prop="createName" label="创建人"></el-table-column>
        <el-table-column label="操作" header-align="right" align="right">
          <template slot-scope="scope">
            <el-button type="text" @click="add(scope.row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </div>
    <el-dialog title="新增/编辑标签" :visible.sync="visible" width="800px">
      <el-form
        :model="form"
        label-width="100px"
        :rules="dataRules"
        ref="form"
        class="dialog-form-con"
      >
        <el-form-item label="标签名称" prop="labelName">
          <el-input
            v-model="form.labelName"
            placeholder="请输入标签名称"
            style="width: 350px"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="选择工作室" prop="teamsId">
          <el-select v-model="form.teamsId" placeholder="请选择" style="width: 70%">
            <el-option
              v-for="item in teamsManage"
              :key="item.id"
              :label="item.teamsName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>-->
        <el-form-item label="标签类型" prop="labelType">
          <el-select
            placeholder="请选择标签类型"
            v-model="form.labelType"
            clearable
            style="width: 350px"
          >
            <el-option
              v-for="(item, index) in typeList"
              :key="index"
              :value="item.value"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Qs from "qs";
export default {
  name: "label",
  data() {
    return {
      select: "1",
      labelName: "",
      enertyId: "",
      labelContent: "",
      labelType: "",
      createBy: "",
      dataList: [],
      dataListLoading: true, //列表loading
      dataListSelections: [], //选中的列
      pageNo: 1,
      pageSize: 10,
      totalPage: 0, //总页数
      dateRangeTime: [],
      teamsManage: [],
      typeList: [],
      visible: false,
      form: {
        labelName: "",
        teamsId: "",
        labelType: "",
        type: 1, //1标签2分组
      },
      dataRules: {
        labelName: [
          { required: true, message: "请输入标签名称", trigger: "blur" },
        ],
        teamsId: [
          { required: true, message: "请选择工作室", trigger: "change" },
        ],
        labelType: [
          { required: true, message: "请选择标签类型", trigger: "change" },
        ],
      },
      edit: false,
      flag: true, //防止重复点击
    };
  },
  async mounted() {
    await this.getTypeList();
    await this.getList();
    if (localStorage.teamsManages) {
      this.teamsManage = JSON.parse(localStorage.teamsManages);
    }
  },
  methods: {
    async getList() {
      const user = JSON.parse(localStorage.getItem("userInfo"));
      let teamsId = "";
      let enertyId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
        enertyId = JSON.parse(localStorage.currentTeam).entityId;
      }
      let params = {
       
		stringParam5: this.labelType,
		stringParam11: teamsId,
		intParam1: this.pageSize,
		intParam2: this.pageNo,
		intParam3: 1,
		stringParam8: user.id
      };
      if (this.select == 1) {
        params.stringParam1 = this.labelName;
      } else {
        params.stringParam2 = this.labelName;
      }
      if (this.dateRangeTime && this.dateRangeTime.length) {
        params.stringParam9 = this.dateRangeTime[0];
        params.stringParam10 = this.dateRangeTime[1];
      }
      const { data: res } = await this.$httpAes({
        url: this.$httpAes.adornUrl("/mbglLabel/list"),
        method: "post",
        data: params,
      });
      if (res.status) {
        this.dataList = res.data;
        this.totalPage = res.totalCount;
      }
      this.dataListLoading = false;
    },
    async changeState(row) {
      console.log(row.status);
      const { data: res } = await this.$httpAes({
        url: this.$httpAes.adornUrl("/mbglLabel/updateStatusById"),
        method: "post",
        params: {
          stringParam1: row.id,
          intParam1: row.status == 1 ? 3 : 1,
        },
      });
      console.log(res, "res");
      if (res.status) {
        this.$message.success(res.msg);
        this.getList();
      }
    },
    add(item) {
      this.visible = true;
      if (item && item.id) {
        this.form = JSON.parse(JSON.stringify(item));
      } else {
        this.$nextTick(() => {
          this.$refs["form"].resetFields();
          this.form = {
            labelName: "",
            labelType: "",
            enertyId: "",
            teamsId: "",
            type: 1,
          };
          if (localStorage.currentTeam) {
            this.form.teamsId = JSON.parse(localStorage.currentTeam).id;
            this.form.enertyId = JSON.parse(localStorage.currentTeam).entityId;
          }
        });
      }
    },
    cancel() {
      this.$refs["form"].resetFields();
      this.visible = false;
      this.form = {
        labelName: "",
        labelType: "",
        enertyId: "",
        teamsId: "",
      };
    },
    async submit() {
      if (this.teamsManage.length) {
        this.teamsManage.forEach((ele) => {
          if (ele.id == this.form.teamsId) {
            this.form.enertyId = ele.entityId;
            this.form.teamsName = ele.teamsName;
          }
        });
      }
      if (localStorage.getItem("userInfo")) {
        const user = JSON.parse(localStorage.getItem("userInfo"));
        this.form.hospitalId = user.deptPid;
        this.form.deptId = user.deptId;
      }
      if (this.form.id) {
        this.$delete(this.form, "mbglLabelIndex");
      }
      this.$refs.form.validate(async (valid) => {
        if (this.flag) {
          this.flag = false;
          if (valid) {
            const { data: res } = await this.$httpAes({
              url: this.$httpAes.adornUrl(
                this.form.id ? "/mbglLabel/update" : "/mbglLabel/add"
              ),
              method: "post",
              data: this.form,
            });
            if (res.status) {
              this.$message({
                message: res.msg,
                type: "success",
                duration: 1500,
                onClose: () => {
                  this.visible = false;
                  this.cancel();
                  this.getList();
                  this.flag = true;
                },
              });
            } else {
              this.$message.error(res.msg);
              this.flag = true;
            }
          } else {
            return false;
          }
        }
      });
    },
    async deleteHandle() {
      this.$confirm("确定进行[删除标签]操作", "提示", {
        confirmButtonText: "确定",
        cancelButtonClass: "btn-custom-cancel",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const ids = [];
          this.dataListSelections.forEach((v) => {
            ids.push(v.id);
          });
          const id = ids.join(`,`);
          const { data: res } = await this.$httpAes({
            url: this.$httpAes.adornUrl(`/mbglLabel/delete?ids=${id}`),
           method: "post",
           data: {
           	stringParam1:id
           }
          });
          if (res.status) {
            this.$message({
              message: res.msg,
              type: "success",
              duration: 1500,
              onClose: () => {
                this.getList();
              },
            });
          }
        })
        .catch(() => {});
    },
    async getTypeList() {
      const { data: res } = await this.$httpAes({
        url: this.$httpAes.adornUrl("/dict/list"),
        method: "post",
        data: {
          stringParam1: "标签类型",
        },
      });
      // console.log(res,'res')
      this.typeList = res.data;
    },
    reset() {
      this.labelName = "";
      this.labelType = "";
      this.dateRangeTime = [];
      this.getList();
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageNo = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageNo = val;
      this.getDataList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
  },
};
</script>

<style lang="scss" scoped></style>
